import React from "react";
import CookieConsent from "react-cookie-consent";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import "./_cookie-bar.scss";

const CookieBar = () => {
  return (
    <CookieConsent
      enableDeclineButton
      buttonText="Sutinku"
      declineButtonText="Nesutinku"
      cookieName="gatsby-gdpr-google-analytics"
      disableStyles={true}
      containerClasses="cookie-bar__container"
      declineButtonClasses="cookie-bar__btn cookie-bar__btn--decline"
      buttonWrapperClasses="cookie-bar__btn-container"
      buttonClasses="cookie-bar__btn"
    >
      Šioje svetainėje naudojami slapukai (angl. cookies). Savo duotą sutikimą
      bet kada galėsite atšaukti pakeisdami savo interneto naršyklės nustatymus.
      Daugiau informacijos apie slapukus rasite{" "}
      <AniLink
        className="cookie-bar__link"
        to="/privatumo-politika"
        cover
        duration={1.5}
        bg="#3e563e"
      >
        Privatumo politikoje
      </AniLink>
      .
    </CookieConsent>
  );
};

export default CookieBar;
